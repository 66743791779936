var isMobile = new Object();
isMobile.Android = function () {
	return navigator.userAgent.match(/Android/i);
};

isMobile.BlackBerry = function () {
	return navigator.userAgent.match(/BlackBerry/i);
};
isMobile.iOS = function () {
	return navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

isMobile.iPad = function () {
	return navigator.userAgent.match(/iPad/i);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera Mini/i);
};
isMobile.Windows = function () {
	return navigator.userAgent.match(/IEMobile/i);
};

isMobile.Firefox = function () {
	return navigator.userAgent.match(/Firefox/gi);
};
isMobile.InternetExplorer = function () {
	return navigator.userAgent.match(/MSIE/gi);
};
isMobile.Opera = function () {
	return navigator.userAgent.match(/Opera/gi);
};
isMobile.Safari = function () {
	return navigator.userAgent.match(/Safari/gi);
};
isMobile.any = function () {
	return (
		isMobile.Android() ||
		isMobile.BlackBerry() ||
		isMobile.iOS() ||
		isMobile.Opera() ||
		isMobile.Windows()
	);
};
if (isMobile.any() && isMobile.iPad() == null) {
	//return;
}

var ua = window.navigator.userAgent;
var is_ie = /MSIE|Trident/.test(ua);

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

var winWT = $(window).innerWidth();
var winHT = $(window).innerHeight();

var disableScrolling = function () {
	var x = window.scrollX;
	var y = window.scrollY;
	window.onscroll = function () { window.scrollTo(x, y); };
}
var enableScrolling = function () {
	window.onscroll = function () { };
}
window.onbeforeunload = function () {
	window.scrollTo(0, 0);
}

$(window).on('load', function () {
	//window.scrollTo(0, 0);
	if (window.location.hash !== "") {
        var hash = window.location.hash;
        console.log(hash);
        $('html, body').animate({
            scrollTop: $(hash).offset().top - 50
        }, 200);
    }
});

var winWT = $(window).width();
$(document).ready(() => {
	var headerHT = $('header').innerHeight();
	$(".slimmenu").slimmenu({
		resizeWidth: '1023',
		collapserTitle: 'Main Menu',
		animSpeed: 'medium',
		easingEffect: null,
		indentChildren: false,
		childrenIndenter: '&nbsp;'
	});

	var navWT = $("header .top-nav-hld nav").innerWidth();
	$(".nav-icon").on('click', function () {
		//return;
		if ($(this).hasClass("open")) {
			resetMobileMenu();
		} else {
			$(this).addClass("open");
			 $("header .top-nav-hld nav").animate({ right: 0 }, 300);
			 $('body').css({ overflow: 'hidden' });
			$('.menu_overlay').css({ display: 'block' });
			$('body').css({ overflow: 'hidden' });
		}
	});

	function resetMobileMenu() {
		$(".nav-icon").removeClass("open");
		$("header .top-nav-hld nav").animate({ right: -navWT + "px" }, 300);
		$('body').css({ overflowY: 'scroll' });
		$('.menu_overlay').css({ display: 'none' });
		$('body').css({ overflowY: 'scroll' });
	}

	function closeMobileMenu(){
		winWT = $(window).width();
		if(winWT <= 1023){
			$('header a').on('click', function(){
				resetMobileMenu();
			});
		}
	}

	$('.menu_overlay').on('click', function(){
		resetMobileMenu();
	});

	function setTopSubHeader(){
		winWT = $(window).width();
		if(winWT <= 1023){
			var tempTopNav = $('header').find('.secondary-nav-hld').remove();
			$(tempTopNav).insertAfter('nav ul.slimmenu');
		}else{
			var tempTopNav = $('header nav').find('.secondary-nav-hld').remove();
			$(tempTopNav).css({display:'none'});
			$(tempTopNav).insertBefore('.top-nav-hld');
			$(tempTopNav).css({display:'block'});
		}
	}
	setTopSubHeader();

	$(window).scroll(function() {
		if( $(this).scrollTop() > headerHT ) {
			$('header').addClass('sticky');
		} else {
			$('header').removeClass('sticky');
		}
	});

	/*****Header Search******/
	var searchInput = $('.gs-panel').find('input');
	var clearClose = $('.clear-search');
	function showClearSearch() {
		if ($(searchInput).val().trim() !== "") {
			$(clearClose).css({ display: 'block' });
		} else {
			$(clearClose).css({ display: 'none' });
		}
	}
	showClearSearch();
	$(searchInput).on('input focus', showClearSearch);

	$(clearClose).on('click', function(){
		$(searchInput).val('');
		$(clearClose).css({ display: 'none' });
	});
	/***********************************************************************/
	/***** O T H E R    P R O D U C T    I N    I N N E R     P A G E S ******/

	if ($('.other-products').length > 0) {
		$(".other-products .carousel-blurb-hld").slick({
			infinite: true,
			arrows: true,
			dots: true,
			fade: false,
			autoplay: true,
			speed: 500,
			autoplaySpeed: 3000,
			slidesToShow: 3,
			slidesToScroll: 3,
			pauseOnFocus: true,
			pauseOnHover: true,
			nextArrow: '<i class="fas fa-chevron-right"></i>',
			prevArrow: '<i class="fas fa-chevron-left"></i>',
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				}
			]
		});
	}

	if ($('.service-slider').length > 0) {
		$(".service-slider").slick({
			infinite: true,
			arrows: true,
			dots: true,
			fade: false,
			autoplay: true,
			speed: 500,
			autoplaySpeed: 3000,
			slidesToShow: 4,
			slidesToScroll: 3,
			pauseOnFocus: true,
			pauseOnHover: true,
			nextArrow: '<i class="fas fa-chevron-right"></i>',
			prevArrow: '<i class="fas fa-chevron-left"></i>',
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				}
			]
		});
	}

	/***********************************************************************/
	/***********************************************************************/

	$('#privacy-policy').on('click', function(e){
		e.preventDefault();
		var iframe = $('#popup-privacy-policy').find('iframe');
		iframe.src = '';
		iframe.src = 'index.html';
		$('#popup-privacy-policy').fadeIn(200);
		$('body').css({overflow:'hidden'});
	});

	$('#popup-privacy-policy .popup-overlay').on('click', function(){
		closePrivacyPopup();
	});

	$('#popup-privacy-policy .close-icon').on('click', function(){
		closePrivacyPopup();
	});
	
	function closePrivacyPopup(){
		$('#popup-privacy-policy').css({display:'none'});
		$('body').css({overflow:'auto'});
	}

	/***********************************************************************/
	/***********************************************************************/

	$(window).resize(function(){
		setTopSubHeader();
		closeMobileMenu();
	});
	

	function setRotateMsgForMobile() {
		winWT = $(window).innerWidth();
		if (winWT < 1024) {
			// Define a function to handle orientation changes
			function handleOrientationChange(event) {
				const isLandscape = event.matches;
				$(".rotate-msg").css("display", isLandscape ? "block" : "none");
			}
			// Create a media query for landscape orientation
			const landscapeMediaQuery = window.matchMedia("(orientation: landscape)");

			// Listen for changes in orientation using the media query
			landscapeMediaQuery.addListener(handleOrientationChange);

			// Initial check
			handleOrientationChange(landscapeMediaQuery);
		}
	}
	//setRotateMsgForMobile();

	$('header li a[href*="#"]').on('click', function(event) {
		if (this.hash !== "") {
		  //event.preventDefault();
		  // Store hash
		  var hash = this.hash;
		  console.log(hash);
		  $('html, body').animate({
			scrollTop: $(hash).offset().top - 50
		  }, 200, );
		}
	});

	$('footer a[href*="#"]').on('click', function(event) {
		if (this.hash !== "") {
		  //event.preventDefault();
		  // Store hash
		  var hash = this.hash;
		  console.log(hash);
		  $('html, body').animate({
			scrollTop: $(hash).offset().top - 50
		  }, 200, );
		}
	});
	
});